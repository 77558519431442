import { isEmailValid } from '@/helpers/email'

export function required(value) {
  return !!value || 'Erforderlich.'
}

export function email(value) {
  return isEmailValid(value) || 'Ungültige E-Mail.'
}

export function length(max) {
  return value => max >= value.length || `Dieser Wert ist zu lang. Es sollte ${max} Zeichen oder weniger haben.`
}
